import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { Button, Icon, Video, Input, Form, FormItem, Navbar, TextArea, Switch, Picker, Popup, Sku, InputNumber, Address, Elevator, ActionSheet, Radio, RadioGroup, Checkbox, CheckboxGroup, Tabs, TabPane, Empty, InfiniteLoading, Dialog, Toast, Grid, GridItem, List, Sticky, Swiper, SwiperItem, Swipe, Steps, Step, CountDown, Price, Cell, CellGroup } from "@nutui/nutui";
import "./assets/scss/reset.scss";

const { use } = createApp(App);

use(Button).use(Icon).use(Video).use(Input).use(Form).use(FormItem).use(Navbar).use(TextArea).use(Switch).use(Picker).use(Popup).use(Sku).use(InputNumber).use(Address).use(Elevator).use(ActionSheet).use(Radio).use(RadioGroup).use(Checkbox).use(CheckboxGroup).use(Tabs).use(TabPane).use(Empty).use(InfiniteLoading).use(Dialog).use(Toast).use(List).use(Grid).use(GridItem).use(Swipe).use(Sticky).use(Swiper).use(SwiperItem).use(Steps).use(Step).use(CountDown).use(Price).use(Cell).use(CellGroup);

use(store).use(router).mount("#app");
