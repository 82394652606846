import router from "@/router";
import { apiUserInfo, apiLogin, apiWxLogin } from "@/service";
import { setToken, setSessionId, setUserInfo, getToken, getSessionId, getUserInfo, getFrom } from "@/utils/cookies";
import { Toast } from '@nutui/nutui';

console.log(typeof getUserInfo())
console.log(getUserInfo())
const state = {
  token: getToken() || '',
  sessionId: getSessionId() || '',
  userInfo:
    (typeof getUserInfo() === "string") && getUserInfo() && getUserInfo() !== 'undefined'
      ? JSON.parse(getUserInfo())
      : getUserInfo(),
  from: getFrom() ? getFrom() : '',
}

const getters = {};

const actions = {
  login({ commit }, params) {
    return new Promise(async (resolve, reject) => {
      Toast.loading("请稍后...");
      const { resultCode, resultData, resultMsg } = await apiLogin(params);
      Toast.hide();
      if (resultCode === '200') {
        console.log(resultCode, resultData, resultMsg);
        let obj = {
          appKey: localStorage.appKey ? localStorage.appKey : "xinhushigerenduan_A",  // 健身衣 AIArmourAPP  心护士 xinhushigerenduan_A
          userPhone: resultData.info.phone,
          userId: resultData.info.userId,
          nickname: resultData.info.name,
        }
        console.log("保存全局用户数据", obj);

        setToken(resultData.access_token, null)
        await commit('SET_TOKEN', resultData.access_token)

        setSessionId(resultData.refresh_token, null)
        await commit('SET_SESSION_ID', resultData.refresh_token)

        setUserInfo(JSON.stringify(obj), null)
        await commit('SET_USER_INFO', obj)

        router.push({
          path: '/shop',
          query: {
            appKey: localStorage.appKey ? localStorage.appKey : "xinhushigerenduan_A",
            userPhone: resultData.info.phone,
            userId: resultData.info.userId
          }
        })
      }
      else Toast.warn(resultMsg);
    })
  },
  wxLogin({ commit }, params) {
    return new Promise(async (resolve, reject) => {
      Toast.loading("请稍后...");
      const { resultCode, resultData, resultMsg } = await apiWxLogin(params);
      Toast.hide();
      if (resultCode === '200') {
        console.log(resultCode, resultData, resultMsg);
        let obj = {
          appKey: localStorage.appKey ? localStorage.appKey : "xinhushigerenduan_A",  // 健身衣 AIArmourAPP  心护士 xinhushigerenduan_A
          userPhone: resultData.info.phone,
          userId: resultData.info.userId,
          nickname: resultData.info.name,
        }
        console.log("保存全局用户数据", obj);

        setToken(resultData.access_token, null)
        await commit('SET_TOKEN', resultData.access_token)

        setSessionId(resultData.refresh_token, null)
        await commit('SET_SESSION_ID', resultData.refresh_token)

        setUserInfo(JSON.stringify(obj), null)
        await commit('SET_USER_INFO', obj)

        router.push({
          path: '/shop',
          query: {
            appKey: localStorage.appKey ? localStorage.appKey : "xinhushigerenduan_A",
            userPhone: resultData.info.phone,
            userId: resultData.info.userId
          }
        })
      }
      else Toast.warn(resultMsg);
    })
  },
  setUserInfo({ commit }, userInfo) {
    return new Promise(async (resolve, reject) => {
      const params = {
        appKey: userInfo.appKey === "AIArmourAPP_A" ||
          userInfo.appKey === "AIArmourAPP_I" ||
          userInfo.appKey === "YijianAPP_A" ||
          userInfo.appKey === "YijianAPP_I"
          ? "w_almond"
          : "w_bmecg",
        phoneNumber: userInfo.userPhone
      }
      const res = await apiUserInfo({ ...params });
      console.log(res)
      if (res.resultCode == "200") {
        let obj = userInfo
        obj.nickname = res.resultData.name
        if (obj.reportId) delete obj.reportId
        console.log("删除reaortId");
        console.log("保存全局用户数据", obj);
        setUserInfo(JSON.stringify(obj), null)
        commit('SET_USER_INFO', obj)
        resolve(true)
      } else {
        router.push('login')
        Toast.warn(res.resultMsg);
      }
    })
  },
};

const mutations = {

  SET_TOKEN: async (state, token) => {
    state.token = token
  },
  SET_SESSION_ID: async (state, session_id) => {
    state.sessionId = session_id
  },
  SET_USER_INFO: async (state, user_info) => {
    state.userInfo = user_info
  },
  SET_FROM: async (state, from) => {
    state.from = from
  },
  SET_OPENID: async (state, openid) => {
    state.openid = openid
  },
};

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
};
