import axios from "axios";
// import {getToken, removeToken} from "../config/auth";
// import router from "./../router";


/**
 *  创建axios实例
 * */
let service = axios.create({
  //baseURL: process.env.BASE_API, // api的base_url
  //baseURL: process.env.VUE_APP_URL, // api的base_url
  timeout: 5000 // 请求超时时间
});

/**
 *  request拦截器(请求)
 * */
service.interceptors.request.use(
  config => {
    /*if (getToken('Token')) {
    config.headers = {
      'Authorization': `Bearer ${getToken('Token')}`
    }
  }*/

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

/**
 *  respone拦截器(响应)
 * */
service.interceptors.response.use(
  response => {
    /*if (response.data.resultCode == '405') {   // 用户未登录
    Message({
      message: response.data.resultMsg,
      type: 'error',
      duration: 2000
    })
    setTimeout(() => {
      // this.$router.push('/login')
      router.replace('/')
      removeToken('Token')
    }, 2000)

  }*/

    //console.log('response的数据是：', response)
    // code: 200，接口正常返回
    //const res = response.data
    /*if (response.status !== 200) {
    Message({
      message: res.message,
      type: 'error',
      duration: 5 * 1000
    })
    // *   根据服务端约定的状态码：
    // *       5001: 非法的 token
    // *       5002: 其他客户端登录了
    // *       5004: Token 过期了
    // *
    /!*if (res.code === 5001 || res.code === 5002 || res.code === 5004) {
      MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        store.dispatch('LogOut').then(() => {
          // 为了重新实例化vue-router对象 避免 bug
          location.reload()
        })
      })
    }*!/
    return Promise.reject('error')
  } else {   // res.code === 200，正常返回数据
    return response.data
  }*/

    return response.data;
  },
  error => {
    /*Message({
    message: error.message,
    type: 'error',
    duration: 1000
  })*/
    return Promise.reject(error);
  }
);

export default service;

/**
 * get 请求方法
 * @param url
 * @param params
 * @returns {Promise}
 */
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    service
      .get(url, {
        params: params
      })
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function post(url, params = {}) {
  return new Promise((resolve, reject) => {
    service
      .post(url, params)
      .then(
        response => {
          // resolve(response.data)
          resolve(response);
        },
        err => {
          //reject(err)
        }
      )
      .catch(error => {
        reject(error)
      });
  });
}
