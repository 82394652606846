/**
 *   数据接口管理
 * */
import { get, post } from "./axios";

const BASE_URL = process.env.VUE_APP_BASE_URL

console.log(BASE_URL)

const BASE_URL_FC = BASE_URL + '/fitnessclothing'

// 获取用户信息
export const apiUserInfo = params => get(BASE_URL + "/usercenter/users/getUserInfoByPhone", params)

export const dataId = params => get("https://api3.benefm.com/bmecg/data/detail", params)

// 登陆
export const apiLogin = params => post(BASE_URL + "/p/login", params)

// 微信登陆
export const apiWxLogin = params => post(BASE_URL + "/p/wxLogin", params)

// 发送验证码
export const apiSmsCode = params => post(BASE_URL + "/p/verify/smsCode", params)

// 测试
export const apiTest = params => get("http://malltestapi.aiarmour.com/fitnessclothing/wechat/jsApiPay", params)


/* #################### 华丽丽分割线 ####################### */

// 获取微信openid
export const apiGetWxOpenId = params => get(BASE_URL_FC + "/wechat/getOpenIdByCode", params);

/**
 *   新版接口 Start
 * */

// 分类商品列表
export const getShopList = params =>
  get(BASE_URL_FC + "/product/getClassificationProdeuctList1", params);

// 商品首页展示
export const getProductBasic = params =>
  get(BASE_URL_FC + "/product/getProductShow1", params);

// H5购买商品时获取商品优惠券【shopDetails组件】
export const getCouponsList = params =>
  get(BASE_URL_FC + "/productGiftCertificate/getProductGiftCertificate", params);

// 得到该商品下用户已经领取的商品券【affirmorders组件获取】
export const getAppUserGiftCertificate = params =>
  get(BASE_URL_FC + "/userGiftCertificate/getAppUserGiftCertificate", params);

// 领取优惠券【affirmorders组件】
export const newCouponGiftCertificate = params =>
  post(BASE_URL_FC + "/userGiftCertificate/newCouponGiftCertificate", params);

// 提交订单
export const apiShopOrderSubmit = params =>
  post(BASE_URL_FC + "/productOrder/createProductOrder1", params);

// 立即使用
export const getUseCertificateProdeuctList = params =>
  get(BASE_URL_FC + "/product/getUseCertificateProdeuctList", params);

// 心护士商品展示【心电解读服务】
export const getProductInfoById = params =>
  get(BASE_URL_FC + "/product/getProductInfoById1", params);

// 心护士创建订单接口【心电解读服务】
export const apiServiceOrderSubmit = params =>
  post(BASE_URL_FC + "/serviceProductOrder/createServiceProductOrder1", params);

// 心护士用户购买的服务商品剩余总次数
export const getPurchaseTimes1 = params =>
  get(BASE_URL_FC + "/serviceProductOrder/getPurchaseTimes1", params);

/**
 *   新版接口 End
 * */

// 心护士分类商品
//export const getClassificationProdeuctList = (params) => get(BASE_URL_FC + '/serviceProduct/getClassificationProdeuctList', params)

// 心护士商品详情【心电解读服务】
export const getServiceProdeuctInfoById = params =>
  get(BASE_URL_FC + "/serviceProduct/getServiceProdeuctInfoById", params);

// 心护士创建订单接口
export const createServiceProductOrder = params =>
  post(BASE_URL_FC + "/serviceProductOrder/createServiceProductOrder", params);

// 心护士用户购买的服务商品剩余总次数
// export const getPurchaseTimes = (params) => get(BASE_URL_FC + '/serviceProductOrder/getPurchaseTimes', params)

/**
 *  服务商品支付：未来健身
 * */
// 微信
//export const weChatPayService = (params) => post(BASE_URL_FC + '/serviceWechat/wechatPay', params)   // 无用
export const weChatPayService = params => post(BASE_URL_FC + "/serviceWechat/wechatPay1", params);

// 支付宝
//export const alipayPayService = (params) => post(BASE_URL_FC + '/service_fitness_alipay/alipay', params)   // 无用
export const alipayPayService = params => post(BASE_URL_FC + "/service_fitness_alipay/alipay1", params);

/**
 *  实体商品支付：善行（服务商品、实体商品 统一）
 * */
// 微信支付---H5
export const weChatPayH5 = params => post(BASE_URL_FC + "/wechat/wechatPay", params);

// 微信支付---公众号、PC
export const weChatPay = params => get(BASE_URL_FC + "/wechat/jsApiPay", params);
// export const weChatPay = params => post("http://192.168.5.124:8080/fitnessclothing/wechat/jsApiPay", params);

// 支付宝
export const alipayPay = params => post(BASE_URL_FC + "/fitness_alipay/alipay", params);

// 订单
export const getOrderList = params =>
  get(BASE_URL_FC + "/productOrder/getProductOrderList", params);

// 服务订单分页列表
export const getServiceProductOrderList = params =>
  get(BASE_URL_FC + "/serviceProductOrder/getServiceProductOrderList", params);

// 心护士商城取消订单
export const cancelServiceProductOrder = params =>
  get(BASE_URL_FC + "/serviceProductOrder/cancelServiceProductOrder", params);

// 心护士商城删除订单
export const deleteServiceProductOrder = params =>
  get(BASE_URL_FC + "/serviceProductOrder/deleteServiceProductOrder", params);

// 商品展示
//export const shopListShow = (params) => get(BASE_URL_FC + '/product/getProductShow', params)

// 心护士商品详情【心电解读服务】
export const shopDetails = params =>
  get(BASE_URL_FC + "/product/getProductInfoById", params);

// 默认地址 【affirmorders】
export const defaultAddress = params =>
  get(BASE_URL_FC + "/shoppingAddress/getDefaultAddress", params);

// 获取地址列表 【address】
export const addressLists = params =>
  get(BASE_URL_FC + "/shoppingAddress/getAddressListByUserId", params);

// 修改地址
export const updateShopAddress = params =>
  post(BASE_URL_FC + "/shoppingAddress/updateShoppingAddress", params);

// 新增地址
export const addShopAddress = params =>
  post(BASE_URL_FC + "/shoppingAddress/addShoppingAddress", params);

// 删除地址
export const deleteShopAddress = params =>
  get(BASE_URL_FC + "/shoppingAddress/deleteShoppingAddress", params);

// 订单详情
//export const ordersDetails= (params) => get(BASE_URL_FC + '/productOrder/getProductOrderInfoById', params)
// 订单详情
export const orderDetails = params =>
  get(BASE_URL_FC + "/productOrder/getProductOrderInfoById1", params);

// 取消订单
//export const cancelOrder = (params) => get(BASE_URL_FC + '/productOrder/cancelProductOrder', params)
export const orderCancel = params =>
  get(BASE_URL_FC + "/productOrder/cancelProductOrder1", params);

// 确认收货
export const orderConfirm = params =>
  get(BASE_URL_FC + "/productOrder/confirmProductOrderReceipt", params);
// 删除订单
export const orderDelete = params =>
  get(BASE_URL_FC + "/productOrder/deleteProductOrder", params);
// 退货申请
export const orderBack = params =>
  post(BASE_URL_FC + "/productOrder/applyReturnGoods", params);

// 创建订单【立即购买--->确认订单--->提交订单】
export const orderCreate = params =>
  post(BASE_URL_FC + "/productOrder/createProductOrder", params);

// 申请发票
export const orderInvoice = params =>
  post(BASE_URL_FC + "/invoice/submitApplyInvoice", params);

// 根据商品id获取该商品下的优惠券
export const shopIdGetCoupon = params =>
  get(BASE_URL_FC + "/productGiftCertificate/getProductGiftCertificateByProductId", params);

// 领取优惠券
export const getCoupon = params =>
  get(BASE_URL_FC + "/userGiftCertificate/couponGiftCertificate", params);

// 得到该商品下用户已经领取的商品券
export const getShopCoupon = params =>
  get(BASE_URL_FC + "/userGiftCertificate/getUserGiftCertificateByUserId", params);

// 分期支付
//export const instalmentsPay = (params) => post(BASE_URL_FC + '/fitness_alipay/tokioAlipay', params)

// 实时物流跟踪
export const orderLogistics = params =>
  post(BASE_URL_FC + "/productOrder/ebusinessOrder", params);
