<template>
  <!-- <router-view #default="{ Component }">
    <keep-alive v-if="isKeepAlive">
      <component :is="Component" />
    </keep-alive>
    <component :is="Component" v-else />
  </router-view> -->
  <router-view />
</template>

<script setup>
import { ref } from "vue";
import router from "@/router";

const isKeepAlive = ref(false);

const routeMeta = router.currentRoute.value.meta;

if (routeMeta.keepAlive) isKeepAlive.value = routeMeta.keepAlive;
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  // height: 100%;
}
#app {
  height: auto;
}
</style>
