import store from '@/store';
import { createRouter, createWebHistory } from 'vue-router'
import { getUserInfo } from '@/utils/cookies'

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: () => import('@/layout/IndexView.vue'),
    redirect: '/shop',
    children: [
      {
        path: '/shop',
        name: 'shop',
        meta: { title: '商城', keepAlive: false },
        component: () => import('@/views/shop/IndexView.vue')
      },
      {
        path: '/order',
        name: 'order',
        meta: { title: '订单', keepAlive: false },
        component: () => import('@/views/order/IndexView.vue')
      },
    ]
  },
  {
    path: '/shopDetails',
    name: 'shopDetails',
    meta: { title: '商品详情', keepAlive: false },
    component: () => import('@/views/shop/DetailsView.vue')
  },
  {
    path: '/logistics',
    name: 'logistics',
    meta: { title: '查看物流', keepAlive: false },
    component: () => import('@/views/order/LogisticsView.vue')
  },
  {
    path: '/invoice',
    name: 'invoice',
    meta: { title: '申请发票', keepAlive: false },
    component: () => import('@/views/order/InvoiceView.vue')
  },
  {
    path: '/backgoods',
    name: 'backgoods',
    meta: { title: '申请退货', keepAlive: false },
    component: () => import('@/views/order/BackgoodsView.vue')
  },
  {
    path: '/service',
    name: 'service',
    meta: { title: '在线客服', keepAlive: false },
    component: () => import('@/views/order/ServiceView.vue')
  },
  {
    path: '/pay',
    name: 'pay',
    meta: { title: '提交订单', keepAlive: false },
    component: () => import('@/views/order/PayView.vue')
  },
  {
    path: '/orderDetails',
    name: 'orderDetails',
    meta: { title: '订单详情', keepAlive: false },
    component: () => import('@/views/order/DetailsView.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: { title: '登陆', keepAlive: false },
    component: () => import('@/views/login/IndexView.vue')
  },
  {
    // 匹配所有路径  vue2使用*   vue3使用/:pathMatch(.*)*或/:pathMatch(.*)或/:catchAll(.*)
    path: "/:catchAll(.*)",
    name: 'page404',
    meta: { title: "404", keepAlive: false },
    component: () => import("@/views/error/IndexView.vue"),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  // 如果已经登录，并且要去登录页，就不让TA去登录页，重定向到首页   // && getToken('Token')
  // 动态改变 title
  // const document: any = new Document();
  if (to.meta.title) document.title = to.meta.title;

  next();

  // if (to.path === "/login") {
  //   next();
  // } else {
  // if((to.query.appKey && to.query.userId && to.query.userPhone) || store.getters.userInfo)  next();
  // if (getUserInfo()) next();
  // let userInfo = store.getters.userInfo ? store.getters.userInfo : typeof getUserInfo() === "string" ? JSON.parse(getUserInfo()) : getUserInfo();

  // console.log(userInfo)
  // console.log(to)
  // if (userInfo) 
  // next();
  // else if ((to.path === '/shop' || to.path === '/order') && to.query.appKey && to.query.userId && to.query.userPhone) next();
  // else next({ path: "/login" });
  // }
});

export default router
